import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDHVUNGZmuCum9YkhJjiNErsfEcAAv1CiA",
  authDomain: "budgetwithme-72bd6.firebaseapp.com",
  projectId: "budgetwithme-72bd6",
  storageBucket: "budgetwithme-72bd6.appspot.com",
  messagingSenderId: "450752699794",
  appId: "1:450752699794:web:02a722e111c4ea8f6b754b",
};

export const app = initializeApp(firebaseConfig);
