import React, { useState } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { app } from "./../firebase";

const RegisterInterest: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);

  const db = getFirestore(app);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      setMessage("Please enter a valid email");
      return;
    }

    setLoading(true);
    setMessage(null);

    try {
      const docRef = await addDoc(collection(db, "registeredEmails"), {
        email,
        timestamp: new Date(),
      });
      setMessage("Thanks for registering your interest!");
      setEmail("");
    } catch (error) {
      console.error("Error adding document: ", error);
      setMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-6 sm:gap-8 md:justify-center md:pl-8">
      <div className="flex flex-col gap-2 text-left">
        <h1 className="text-3xl text-white font-black leading-tight tracking-[-0.033em] sm:text-4xl md:text-5xl">
          Coming Soon: Budgeting made easy
        </h1>
        <h2 className="text-white text-sm font-normal leading-normal sm:text-base">
          Save for the things that matter, together. Join our community of
          budgeters and register your interest today.
        </h2>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col h-14 w-full sm:h-16"
      >
        <div className="flex w-full items-stretch rounded-xl h-full">
          <div className="text-[#9da6b8] flex items-center justify-center pl-4 bg-[#292e38] rounded-l-xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
            </svg>
          </div>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full flex-1 text-white border-none focus:outline-none bg-[#292e38] rounded-r-none pl-4"
            required
          />
          <button
            type="submit"
            className="text-white min-w-[84px] px-4 bg-[#195de6] font-bold rounded-r-xl"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Get Notified"}
          </button>
        </div>
        {message && <p className="text-white mt-2">{message}</p>}
      </form>
    </div>
  );
};

export default RegisterInterest;
