import React, { useState } from "react";
import RegisterInterest from "./RegisterInterest";

const ComingSoon: React.FC = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  
  return (
    <div
      className="relative flex min-h-screen flex-col bg-[#111318] overflow-x-hidden"
      style={{ fontFamily: '"Work Sans", "Noto Sans", sans-serif' }}
    >
      <div className="flex h-full grow flex-col">
        {/* Header */}
        <header className="fixed top-0 z-50 w-full flex items-center bg-[#111318] justify-between border-b border-[#292e38] px-4 py-3 sm:px-6 md:px-10">
          {/* Logo and Site Name */}
          <div className="flex items-center gap-2 text-white">
            <div className="w-8 h-8">
              <img
                className="w-full h-full object-contain invert"
                src="/logo.svg"
                alt="logo"
              />
            </div>
            <h2 className="text-base font-bold leading-tight tracking-[-0.015em] sm:text-lg">
              BudgetWith.Me
            </h2>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex flex-1 justify-end items-center gap-4 sm:gap-6 md:gap-8">
            <a
              className="text-white text-sm font-medium leading-normal"
              href="#"
            >
              About Us
            </a>
            <div className="flex gap-2">
              <button className="text-white flex min-w-[84px] items-center justify-center rounded-xl h-10 px-4 bg-[#195de6] text-sm font-bold leading-normal">
                Log In
              </button>
              <button className="text-white flex min-w-[84px] items-center justify-center rounded-xl h-10 px-4 bg-[#292e38] text-sm font-bold leading-normal">
                Sign Up
              </button>
            </div>
          </div>

          {/* Mobile Menu Toggle */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              className="text-white focus:outline-none"
            >
              <i
                className={`fas ${
                  isMobileMenuOpen ? "fa-times" : "fa-bars"
                } text-2xl`}
              ></i>
            </button>
          </div>
        </header>

        {/* Mobile Navigation Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden fixed top-16 left-0 right-0 bg-[#111318] border-b border-[#292e38] z-40">
            <nav className="flex flex-col items-center gap-4 py-4">
              <a
                className="text-white text-sm font-medium leading-normal"
                href="#"
              >
                About Us
              </a>
              <button className="text-white flex w-3/4 items-center justify-center rounded-xl h-10 px-4 bg-[#195de6] text-sm font-bold leading-normal">
                Log In
              </button>
              <button className="text-white flex w-3/4 items-center justify-center rounded-xl h-10 px-4 bg-[#292e38] text-sm font-bold leading-normal">
                Sign Up
              </button>
            </nav>
          </div>
        )}

        <div className="mt-20 px-4 py-5 flex flex-1 justify-center sm:px-6 md:px-10 lg:px-20 xl:px-40">
          <div className="flex flex-col max-w-[960px] flex-1">
            <div className="container">
              <div className="flex flex-col gap-6 px-4 py-10 sm:gap-8 md:flex-row">
                <div
                  className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                  style={{
                    backgroundImage: `url("./images/piggy_bank.jpg")`,
                  }}
                ></div>
                <RegisterInterest />
              </div>
            </div>

            <div className="flex flex-col gap-6 px-4 py-10 sm:gap-8">
              <h1 className="text-white tracking-light text-2xl font-bold leading-tight sm:text-3xl md:text-4xl max-w-[720px]">
                Why budget with us?
              </h1>
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
                {/* Plan for Your Future */}
                <div className="flex flex-col gap-3 pb-3">
                  <div
                    className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                    style={{
                      backgroundImage: `url("./images/charts.jpg")`,
                    }}
                  ></div>
                  <div>
                    <p className="text-white text-base font-medium leading-normal">
                      Plan for your future
                    </p>
                    <p className="text-[#9da6b8] text-sm font-normal leading-normal">
                      Set goals and build a plan to reach them
                    </p>
                  </div>
                </div>
                {/* Stay on Track */}
                <div className="flex flex-col gap-3 pb-3">
                  <div
                    className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                    style={{
                      backgroundImage: `url("./images/pebbles.jpg")`,
                    }}
                  ></div>
                  <div>
                    <p className="text-white text-base font-medium leading-normal">
                      Stay on track
                    </p>
                    <p className="text-[#9da6b8] text-sm font-normal leading-normal">
                      We'll help you track and adjust as needed
                    </p>
                  </div>
                </div>
                {/* Know Your Spending */}
                <div className="flex flex-col gap-3 pb-3">
                  <div
                    className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                    style={{
                      backgroundImage: `url("./images/calculator.jpg")`,
                    }}
                  ></div>
                  <div>
                    <p className="text-white text-base font-medium leading-normal">
                      Know your spending
                    </p>
                    <p className="text-[#9da6b8] text-sm font-normal leading-normal">
                      Understand where your money is going
                    </p>
                  </div>
                </div>
                {/* Pay Off Debt */}
                <div className="flex flex-col gap-3 pb-3">
                  <div
                    className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                    style={{
                      backgroundImage: `url("./images/coins.jpg")`,
                    }}
                  ></div>
                  <div>
                    <p className="text-white text-base font-medium leading-normal">
                      Pay off debt
                    </p>
                    <p className="text-[#9da6b8] text-sm font-normal leading-normal">
                      Get support paying down credit cards and loans
                    </p>
                  </div>
                </div>
                {/* Save for What You Want */}
                <div className="flex flex-col gap-3 pb-3">
                  <div
                    className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                    style={{
                      backgroundImage: `url("./images/bulb.jpg")`,
                    }}
                  ></div>
                  <div>
                    <p className="text-white text-base font-medium leading-normal">
                      Save for what you want
                    </p>
                    <p className="text-[#9da6b8] text-sm font-normal leading-normal">
                      Save up for a trip, a new computer, or anything else
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <h2 className="text-white text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5 sm:text-2xl md:text-3xl">
              How it works
            </h2>
            <div className="grid grid-cols-1 gap-5 p-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
              <div className="flex gap-3 rounded-lg border border-[#3c4453] bg-[#1c1f26] p-4 flex-col">
                <div className="text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                  >
                    <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                  </svg>
                </div>
                <div className="flex flex-col gap-1">
                  <h2 className="text-white text-base font-bold leading-tight">
                    Explore our features
                  </h2>
                  <p className="text-[#9da6b8] text-sm font-normal leading-normal">
                    Take a tour of our tools and resources to see how we can
                    help
                  </p>
                </div>
              </div>
              <div className="flex gap-3 rounded-lg border border-[#3c4453] bg-[#1c1f26] p-4 flex-col">
                <div className="text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                  >
                    <path d="M152,120H136V56h8a32,32,0,0,1,32,32,8,8,0,0,0,16,0,48.05,48.05,0,0,0-48-48h-8V24a8,8,0,0,0-16,0V40h-8a48,48,0,0,0,0,96h8v64H104a32,32,0,0,1-32-32,8,8,0,0,0-16,0,48.05,48.05,0,0,0,48,48h16v16a8,8,0,0,0,16,0V216h16a48,48,0,0,0,0-96Zm-40,0a32,32,0,0,1,0-64h8v64Zm40,80H136V136h16a32,32,0,0,1,0,64Z"></path>
                  </svg>
                </div>
                <div className="flex flex-col gap-1">
                  <h2 className="text-white text-base font-bold leading-tight">
                    Set up your budget
                  </h2>
                  <p className="text-[#9da6b8] text-sm font-normal leading-normal">
                    Add your income and expenses, and set goals to stay on track
                  </p>
                </div>
              </div>
              <div className="flex gap-3 rounded-lg border border-[#3c4453] bg-[#1c1f26] p-4 flex-col">
                <div className="text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                  >
                    <path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path>
                  </svg>
                </div>
                <div className="flex flex-col gap-1">
                  <h2 className="text-white text-base font-bold leading-tight">
                    Track your progress
                  </h2>
                  <p className="text-[#9da6b8] text-sm font-normal leading-normal">
                    Use our dashboard to see how you're doing and make
                    adjustments as needed
                  </p>
                </div>
              </div>
              <div className="flex gap-3 rounded-lg border border-[#3c4453] bg-[#1c1f26] p-4 flex-col">
                <div className="text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                  >
                    <path d="M230.92,212c-15.23-26.33-38.7-45.21-66.09-54.16a72,72,0,1,0-73.66,0C63.78,166.78,40.31,185.66,25.08,212a8,8,0,1,0,13.85,8c18.84-32.56,52.14-52,89.07-52s70.23,19.44,89.07,52a8,8,0,1,0,13.85-8ZM72,96a56,56,0,1,1,56,56A56.06,56.06,0,0,1,72,96Z"></path>
                  </svg>
                </div>
                <div className="flex flex-col gap-1">
                  <h2 className="text-white text-base font-bold leading-tight">
                    Join a community
                  </h2>
                  <p className="text-[#9da6b8] text-sm font-normal leading-normal">
                    Connect with other budgeters to share tips and support each
                    other
                  </p>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="flex flex-col justify-end gap-6 px-4 py-10 sm:gap-8 sm:px-10 sm:py-20">
                <div className="flex flex-col gap-2 text-center">
                  <h1 className="text-white tracking-light text-2xl font-bold leading-tight sm:text-3xl md:text-4xl">
                    Ready to take control of your money?
                  </h1>
                  <p className="text-white text-base font-normal leading-normal">
                    Join over 100,000 people who have started budgeting with us.
                  </p>
                </div>
                <div className="flex justify-center">
                  <button className="text-white flex min-w-[84px] items-center justify-center rounded-xl h-10 px-4 sm:h-12 sm:px-5 bg-[#195de6] text-sm font-bold">
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="bg-[#111318] border-t border-[#292e38] px-4 py-6 sm:px-6 md:px-10">
          <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
            <div className="flex items-center gap-2 text-white">
              <div className="w-8 h-8">
                <img
                  className="w-full h-full object-contain invert"
                  src="/logo.svg"
                  alt="logo"
                />
              </div>
              <h2 className="text-base font-bold leading-tight tracking-[-0.015em] sm:text-lg">
                BudgetWith.Me
              </h2>
            </div>
            <div className="flex gap-4 text-white">
              <a href="#" className="text-sm font-medium leading-normal">
                Privacy Policy
              </a>
              <a href="#" className="text-sm font-medium leading-normal">
                Terms of Service
              </a>
              <a href="#" className="text-sm font-medium leading-normal">
                Contact Us
              </a>
            </div>
          </div>
          <div className="mt-4 text-center text-[#9da6b8] text-sm">
            © {new Date().getFullYear()} BudgetWith.Me. All rights reserved.
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ComingSoon;
